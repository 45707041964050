// 多图片上传

<template>
  <div class="upload">
    <el-upload
      ref="myUploader"
      list-type="picture-card"
      :headers="headers"
      :action="action"
      name="attach"
      :accept="accept"
      :limit="limit"
      :file-list="fileList"
      class="my-uploader"
      :before-upload="beforeUpload"
      :on-exceed="onExceed"
      :on-progress="onProgress"
      :on-success="onSuccess"
    >
      <!-- <div class="content-box"></div>
      <div v-for="item in list" :key="item">
        <el-progress v-if="status === 'uploading'" type="circle" :percentage="progress"></el-progress>

        <div v-if="imgUrl && status === 'success'" class="img-box">
          <img :src="imgUrl" class="img" />
          <div class="mask">
            <i class="el-icon-edit" slot="trigger"></i>
            <i class="el-icon-delete" @click.stop="handleDelete"></i>
          </div>
        </div>
      </div> -->

      <div slot="file" slot-scope="{ file }">
        <!-- {{ file }} -->
        <img v-if="file.status === 'success'" class="el-upload-list__item-thumbnail" :src="file.url" />
        <el-progress v-if="file.status === 'uploading'" type="circle" :percentage="progress"></el-progress>
        <span class="el-upload-list__item-actions">
          <!-- 预览 -->
          <!-- <span class="el-upload-list__item-preview" @click="handlePictureCardPreview(file)">
            <i class="el-icon-zoom-in"></i>
          </span> -->
          <!-- 修改 -->
          <span class="el-upload-list__item-delete" @click="handleEdit(file)">
            <i class="el-icon-edit"></i>
          </span>
          <!-- 删除 -->
          <span class="el-upload-list__item-delete" @click="handleRemove(file)">
            <i class="el-icon-delete"></i>
          </span>
        </span>
      </div>

      <div class="plus-box" @click="handlePlusClick">
        <i class="el-icon-plus"></i>
      </div>
      <div v-if="tip" slot="tip" class="el-upload__tip">{{ tip }}</div>
    </el-upload>

    <el-upload
      v-show="false"
      ref="myUploader2"
      list-type="picture-card"
      :headers="headers"
      :action="action"
      name="attach"
      :accept="accept"
      :file-list="fileList2"
      class="my-uploader"
      :before-upload="beforeUpload"
      :on-progress="onProgress"
      :on-success="onSuccess"
    ></el-upload>
  </div>
</template>

<script>
export default {
  name: '',
  components: {},

  props: {
    list: { type: Array, required: true },
    accept: { type: String, default: 'image/jpeg,image/png' },
    size: { type: [Number, String], default: 100 },
    limit: { type: [Number, String], default: 10 },
    tip: { type: String, default: '' }
  },

  data() {
    return {
      headers: { Authorization: localStorage.getItem('token') },
      action: process.env.VUE_APP_BASE_API + '/serve-user/app-api/v1/upload',

      fileList: [],
      fileList2: [],
      progress: 0,
      editNum: -1
    }
  },

  computed: {},

  watch: {
    list: {
      handler(list) {
        if (list) {
          this.fileList = list
        } else {
          this.fileList = []
        }

        this.$nextTick(() => {
          if (list.length < this.limit) {
            this.$refs.myUploader.$refs['upload-inner'].$el.style.display = 'inline-block'
          } else {
            this.$refs.myUploader.$refs['upload-inner'].$el.style.display = 'none'
          }
        })
      },
      immediate: true
    }
  },

  created() {},

  methods: {
    // 上传前触发
    beforeUpload(file) {
      const fileFlag = this.testFile(file)

      if (this.editNum === -1) {
        return fileFlag
      } else {
        if (fileFlag) {
          this.fileList[this.editNum].status = 'uploading'
          this.$forceUpdate() // 强制渲染
          return true
        } else {
          return false
        }
      }
    },

    // 检查文件是否符合规则
    testFile(file) {
      const isLtSize = file.size / 1024 / 1024 < this.size

      if (!isLtSize) {
        this.$message.error('上传图片的大小不能超过 ' + this.size + 'MB!')
      }

      return isLtSize
    },

    // 文件超出限制个数
    onExceed(files, fileList) {
      this.$message.error('超出上传数量限制！')
    },

    // 文件上传时的钩子
    onProgress(event, file, fileList) {
      this.progress = parseInt(event.percent)
    },

    // 上传成功时触发
    onSuccess(res, file, fileList) {
      if (this.editNum === -1) {
        this.fileList.push({
          uid: file.uid,
          url: res.data[0]
        })
      } else {
        this.fileList[this.editNum].status = 'success'
        this.fileList[this.editNum].url = res.data[0]
        this.$forceUpdate()
      }

      this.$emit('change', this.fileList)
    },

    handlePlusClick() {
      this.editNum = -1
    },

    // 修改文件
    handleEdit(file) {
      const index = this.fileList.findIndex((item) => item.uid === file.uid)
      this.editNum = index
      this.$refs.myUploader2.$refs['upload-inner'].handleClick()
    },

    // 删除文件
    handleRemove(file) {
      const arr = [...this.fileList]

      arr.forEach((item, index) => {
        if (item.uid === file.uid) {
          this.fileList.splice(index, 1)
          this.$emit('change', this.fileList)
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">
.upload {
  line-height: normal;

  /deep/ .my-uploader {
    .plus-box {
      width: 100%;
      height: 100%;
    }

    .el-upload__tip {
    }
  }
}
</style>
